import axios from "axios";
const ENDPOINT = "/api/v1/exams-attendance";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT + '/take-attendance', payload);
};



const remove = async payload => {
  return axios.post(ENDPOINT + "/delete", payload);
};

export { get, create, remove };

