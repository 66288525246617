<template>
  <div class="metadata-options">
    <v-card-actions class="pa-0">
      <page-title :title="title" />

      <v-spacer></v-spacer>
      <AddButton
        :create-function="backToExams"
        :icon="'mdi-reply-circle'"
        :title="'BACK TO EXAMS'"
        v-if="openScoreTable"
      />
    </v-card-actions>

    <v-card>
      <e-facilities :set-data="setActive" />
      <LoadExaminations
        :is-open="openExamsLoader"
        :accept-function="getExamsParams"
        :examParams="examParams"
        :buttonText="'Take Attendance'"
        v-if="openExamsLoader"
      />
      <v-data-table
        v-if="openScoreTable"
        :loading="loading"
        :headers="headers"
        :items="students"
        @toggle-select-all="selectedAll"
        @item-selected="itemSelected($event)"
        :server-items-length="params.total"
        :options.sync="pagination"
        :items-per-page="params.size"
        class="elevation-1"
        show-select
        v-model="selected"
        item-key="id"
        :no-data-text="'Data not available from this resource'"
        :footer-props="{
          'items-per-page-options': [20, 50, 100, 300, 500, -1],
        }"
      >
        <!-- <template v-slot:header.data-table-select="{ props, on }">
          <v-simple-checkbox
            color="primary"
            v-if="props.indeterminate"
            v-ripple
            v-bind="props"
            :value="props.indeterminate"
            v-on="on"
          ></v-simple-checkbox>
          <v-simple-checkbox
            color="primary"
            v-if="!props.indeterminate"
            v-ripple
            v-bind="props"
            v-on="on"
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.data-table-select="{ on, props }">
          <v-simple-checkbox
            color="primary"
            v-ripple
            v-bind="props"
            :value="selected"
            v-on="on"
          ></v-simple-checkbox>
        </template> -->
        <template v-slot:top>
          <v-card-title>
            <!-- <v-col cols="3" sm="12" md="3">
              <v-select
                v-model="exam"
                :hint="examData"
                :items="exams"
                item-text="name"
                label="Select Examination"
                persistent-hint
                return-object
                @change="loadStudents($event)"
                @click:clear="loadStudents()"
                single-line
                required
                clearable
              ></v-select>
            </v-col>

            <v-col cols="2" sm="12" md="2" class="pa-5">
              <v-select
                label="Filter by Stream"
                :items="streams"
                :item-text="'name'"
                @change="loadStudentsByStreams($event)"
                @click:clear="loadStudentsByStreams()"
                return-object
                required
                clearable
              ></v-select>
            </v-col> -->
            <v-spacer></v-spacer>
            <v-col cols="6" sm="12" md="4" class="pa-0">
              <SearchField
                :search-function="searchOptions"
                :prepend-icon="'mdi-magnify'"
                :label="'Search Students'"
                :append-icon="''"
                v-if="items.length > 0"
              />
            </v-col>
          </v-card-title>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- {{ item.selected }} -->
          <v-icon v-if="item.sat_for" color="primary --text" class="mr-1 pr-1"
            >mdi-check-bold</v-icon
          >
          <v-icon v-else color="danger --text" class="mr-1 pr-1"
            >mdi-window-close</v-icon
          >
        </template>
        <template v-slot:body.append v-if="attendance.length > 0">
          <v-col class="text-right">
            <v-btn class="ma-2" color="primary" @click="openConfirmDialog">
              <v-icon>mdi-content-save-all-outline</v-icon>
              save attendance
            </v-btn>
          </v-col>
        </template>
      </v-data-table>
    </v-card>

    <ConfirmDialog
      :reject-function="closeConfirmDialog"
      :accept-function="confirmAttendance"
      :item="item"
      :isOpen="openConfirm"
      :title="
        `Are you sure you want to confirm attendance of ${attendance.length} of ${params.total} students ?`
      "
      :loading="loading"
    />
  </div>
</template>

<script>
import * as attendance from "./services";
import { get as getClasses, streams } from "../school-classes/services";
import { get as getExams } from "../examinations/services";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

export default {
  components: {},
  name: "ExaminationAttendance",
  data() {
    return {
      selected: [],
      schoolClassesLists: [],
      schoolClass: {},
      title: "Manage Examination Attendance",
      isEditing: false,
      formData: {},
      dialog: false,
      isOpen: false,
      loading: false,
      openConfirm: false,
      openScoreTable: false,
      params: {},
      item: null,
      schoolClassStream: "",
      items: [],
      attendance: [],
      streams: [],
      exams: [],

      openExamsLoader: false,
      examParams: {},
      exam: {},
      examData: null,
      headers: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          class: "primary--text",
        },
        {
          text: "Exam Number",
          align: "start",
          sortable: false,
          value: "student_class.student_necta_serial",
          class: "primary--text",
        },
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "fullName",
          class: "primary--text",
        },

        {
          text: "Gender",
          align: "start",
          sortable: false,
          value: "student_class.student.gender.descriptions",
          class: "primary--text",
        },
        {
          text: "Class",
          align: "start",
          sortable: false,
          value: "schoolClass.name",
          class: "primary--text",
        },
        {
          text: "Stream",
          align: "start",
          sortable: false,
          value: "school_class_stream.name",
          class: "primary--text",
        },
        {
          text: "Year",
          align: "start",
          sortable: false,
          value: "academic_year.name",
          class: "primary--text",
        },
        {
          text: "Attendance Status",
          value: "actions",
          sortable: false,
          class: "primary--text",
        },
      ],
    };
  },

  computed: {
    students() {
      return this.items.map((item, index) => ({
        ...item,
        index: ++index,
        schoolClass: item.school_class.school_class,
        fullName: item.student_class.student.display_name,
        student: item.student_class.student,
      }));
    },

    pagination: {
      get() {
        return this.params;
      },

      set(value) {
        this.params.page = value.page;
        this.params.size =
          value.itemsPerPage != -1 ? value.itemsPerPage : this.params.total;

        this.params.total = this.params.total;
      },
    },

    academicYear() {
      return this.$mixins.academicYear();
    },
  },

  methods: {
    selectedAll(data) {
      data.items.forEach((item) => {
        item.sat_for = data.value;
        item.exam_id = this.exam.id;
      });
      this.attendance = _.uniqBy(data.items, "student.id");
    },

    itemSelected(data) {
      data.item.sat_for = data.value;
      data.item.exam_id = this.exam.id;

      this.attendance.push(data.item);
      this.attendance = _.uniqBy(this.attendance, "student.id");
      //remove duplicates but keep the last updated entry!
      this.attendance.reverse();
    },

    confirmAttendance() {
      let payload = {
        attendance: this.attendance,
      };

      attendance.create(payload).then((response) => {
        this.init(this.params);
        this.closeConfirmDialog();
      });
    },
    fetchExams() {
      getExams().then((response) => {
        this.exams = response.data.data;
      });
    },
    searchOption() {},

    closeConfirmDialog() {
      this.openConfirm = false;
    },

    init(params) {
      this.loading = true;
      attendance
        .get({
          ...params,
        })
        .then((response) => {
          this.loading = false;
          this.params.total = response.data.total;
          this.items = response?.data?.data;
          this.attendance = [];
          this.selected = response?.data?.data.filter(
            (i) => i.sat_for === true
          );
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    setActive(data) {
      this.openExamsLoader = true;

      this.examParams = data;
    },

    getExamsParams(data, action) {
      this.exam = data;
      if (action === "openScores") {
        this.openExamsLoader = false;
        this.openScoreTable = true;
      } else {
        this.openExamsLoader = true;
        this.openScoreTable = false;
      }

      this.loadStudents(data);
    },

    backToExams() {
      this.openExamsLoader = true;
      this.openScoreTable = false;
    },

    exit() {
      return;
    },

    getSchoolClasses() {
      getClasses().then((response) => {
        this.schoolClassesLists = response.data[0]
          ? response.data[0].data
              .map((item) => ({
                ...item,
                schoolClass: item.school_class,
              }))
              .sort(function(a, b) {
                if (a > b) return 1;
                return -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudents(data = null) {
      this.examData = data
        ? ` Exam Details: ${data.school_class.name} ${data.type.name} - ${data.subject.name} held on ${data.date}`
        : "";

      this.params.schoolClassId =
        data && data.school_class ? data.school_class.id : null;
      this.params.subjectId = data && data.subject ? data.subject.id : null;

      this.init(this.params);
      this.loadStreams(data);

      if (data === null) {
        this.schoolClass = {};
      }
    },

    loadStreams(data) {
      //.params.schoolClassId = data && data.id ? data.id : null;
      //this.loadStudentsByStreams(this.params);
      streams(this.params).then((response) => {
        this.streams = response.data[0]
          ? response.data[0]
              .map((item) => ({
                ...item,
              }))
              .sort(function(a, b) {
                return a.name > b.name ? 1 : -1;
              })
              .map((item, index) => ({
                ...item,
                index: ++index,
              }))
          : [];
      });
    },

    loadStudentsByStreams(data = null) {
      this.params.schoolClassStreamId = data && data.id ? data.id : null;
      this.init(this.params);
    },

    openDialog(data = null) {
      this.dialog = true;
      if (data && data.id) {
        this.formData = data;
      } else {
        this.formData.uuid = uuidv4();
      }
    },

    closeDialog() {
      this.loading = false;
      this.dialog = false;
      this.formData = {};
      //this.finishDataActions()
    },

    openDeleteDialog(item) {
      this.isOpen = true;
      this.item = item;
    },

    searchOptions(optionName) {
      (this.params.search = optionName ? optionName : null),
        this.init({
          size: this.params.size,
          total: this.params.total,
          ...this.params,
        });
    },

    save(data) {
      this.loading = true;

      attendance
        .create(data)
        .then(() => {
          this.finishDataActions();
        })
        .catch((err) => {
          if (err) {
            this.loading = false;
          }
        });
    },
    finishDataActions() {
      this.loading = false;
      this.isOpen = false;
      this.closeDialog();
      this.init(this.params);
    },

    getGenderList() {
      this.$mixins.fetchByCategory("GENDER").then((response) => {
        this.genders = response;
      });
    },

    reAssignNumbers() {
      let params = {
        ...this.params,
        academicYear: this.academicYear.id,
      };
      student.reAssignNumbers(params).then((response) => {
        this.openConfirm = false;
        this.init(this.params);
      });
    },

    openConfirmDialog() {
      this.openConfirm = true;
    },
  },

  watch: {
    pagination: {
      handler() {
        this.loading = true;
        // this.init(this.params);
      },
      update() {
        this.loading = true;
        //this.init(this.params);
      },
      deep: true,
    },
  },

  mounted() {
    this.getSchoolClasses();
    this.getGenderList();
    this.fetchExams();
  },
};
</script>

<style scoped></style>
